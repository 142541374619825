import { Container } from 'react-bootstrap';
import React from "react"

const Portfolio = () => (
    <section className="portfolio" id="portfolio">
        <Container>
            <h2 className="text-center text-uppercase text-secondary mb-0">Portfolio</h2>
            <hr className="star-dark mb-5" />
        </Container>
    </section>
)

export default Portfolio
