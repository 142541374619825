import { Container } from 'react-bootstrap';
import React from "react"

const About = () => (
    <section className="bg-primary text-white mb-0" id="about">
        <Container>
            <h2 className="text-center text-uppercase text-white">About</h2>
            <hr className="star-light mb-5" />
        </Container>
    </section>
)

export default About
