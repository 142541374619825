import { Container } from 'react-bootstrap';
import React from "react"

const Copyright = () => (
    <div className="copyright py-4 text-center text-white">
        <Container>
            <small>Copyright &copy; My Website 2019</small>
        </Container>
    </div>
)

export default Copyright
